import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { withIntl } from '~/utils/withIntl';
import { withWindowSizeContext } from '~/shared/WindowSizeProvider';
import { useLazyAsyncFunction } from '~/utils/useLazyAsyncFunction';
import CodeInputForm from '../CodeApplier/CodeInputForm';
import CodeApplierV1 from '../CodeApplier/CodeApplierContainerV1/CodeApplierV1.imports-loadable';
import useOfferCodeData from '../CodeInputBoxV2/useOfferCodeData';

const CodeInputBox = ({ classes, giftCardMessage, menuItemCart, restaurant, onApplyOfferCodeComplete, t }) => {
  const showWrongLocationOffer = useSelector(state => state.offers.showWrongLocationOffer);
  const orderingOfferLocationDetails = useSelector(state => state.offers.wrongLocationOfferDetails);

  const [code, setCode] = React.useState(orderingOfferLocationDetails?.code || '');

  const [onApplyOfferCode, initOnApplyOfferCode] = useLazyAsyncFunction();
  const [loadCodeApplier, setLoadCodeApplier] = React.useState(false);

  const onFocus = useCallback(() => {
    setLoadCodeApplier(true);
  }, []);
  const { getCodeInputTitle } = useOfferCodeData(restaurant, menuItemCart);

  useEffect(() => {
    if (orderingOfferLocationDetails?.code !== '') {
      setCode(orderingOfferLocationDetails?.code);
    }

    if (menuItemCart.selectedGiftCard || menuItemCart.selectedOrderingOfferCode || showWrongLocationOffer) {
      setLoadCodeApplier(true);
    }
  }, [menuItemCart.selectedGiftCard, menuItemCart.selectedOrderingOfferCode, orderingOfferLocationDetails, showWrongLocationOffer]);

  if (!restaurant || !menuItemCart) {
    return null;
  }

  return (
    <React.Fragment>
      <CodeInputForm
        classes={classes}
        value={code}
        onChange={e => setCode(e.target.value)}
        onApply={onApplyOfferCode}
        onFocus={onFocus}
        title={t(getCodeInputTitle())}
        buttonText={t('consumer.ordering.apply')}
      />
      {loadCodeApplier && (
      <CodeApplierV1
        classes={classes}
        giftCardMessage={giftCardMessage}
        menuItemCart={menuItemCart}
        restaurant={restaurant}
        code={code}
        setCode={setCode}
        onApplyOfferCodeComplete={onApplyOfferCodeComplete}
        initOnApplyOfferCode={initOnApplyOfferCode}
      />
      )}
    </React.Fragment>
  );
};

CodeInputBox.defaultProps = {
  onApplyOfferCodeComplete: () => null,
};

CodeInputBox.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItemCart: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.shape({
      hasToastGiftCardSupport: PropTypes.bool,
      hasToastOfferSupport: PropTypes.bool,
      isToastPaymentAvailable: PropTypes.bool,
    }),
    selectedGiftCard: PropTypes.object,
    selectedOrderingOfferCode: PropTypes.object,
    subtotal: PropTypes.number,
  }).isRequired,
  onApplyOfferCodeComplete: PropTypes.func,
  restaurant: PropTypes.shape({
    featureSetting: PropTypes.shape({
      isGiftCardsEnabled: PropTypes.bool,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
)(CodeInputBox);
