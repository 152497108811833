import React, { useContext, useState } from 'react';
import { compose } from '@shakacode/recompose';

import PropTypes from 'prop-types';
import { Button } from '@popmenu/common-ui';
import { withSnackbar } from '../../../utils/withSnackbar';
import { SquarePaymentContext } from '../SquarePaymentsForm';

const SquareButton = ({
  children,
  createVerificationDetails,
  isOnlinePayment,
  onNonceReceived,
  onPickupSubmit,
  showSnackbarError,
  ...props
}) => {
  const [isVerifyingBuyer, setIsVerifyingBuyer] = useState(false);
  const { paymentSdk, squareCard } = useContext(SquarePaymentContext);

  const handleOnlineSubmit = (e) => {
    const getTokenAndBuyerVerification = async () => {
      setIsVerifyingBuyer(true);
      try {
        const tokenResult = await squareCard.tokenize();
        if (tokenResult?.errors?.length > 0) {
          showSnackbarError(tokenResult.errors[0].message);
        } else {
          const buyerVerificationResult = await paymentSdk.verifyBuyer(tokenResult.token, createVerificationDetails());
          onNonceReceived(tokenResult.token, buyerVerificationResult.token);
        }
      } catch (error) {
        showSnackbarError(error.message);
      }
      setIsVerifyingBuyer(false);
    };

    e.preventDefault();
    getTokenAndBuyerVerification();
  };

  return (
    <Button
      variant="contained"
      {...props}
      loading={isVerifyingBuyer || props?.loading}
      onClick={isOnlinePayment ? handleOnlineSubmit : onPickupSubmit}
    >
      {children}
    </Button>
  );
};

SquareButton.defaultProps = {
  createVerificationDetails: () => {},
  onPickupSubmit: () => {},
};

SquareButton.propTypes = {
  children: PropTypes.node.isRequired,
  createVerificationDetails: PropTypes.func,
  isOnlinePayment: PropTypes.bool.isRequired,
  onNonceReceived: PropTypes.func.isRequired,
  onPickupSubmit: PropTypes.func,
  showSnackbarError: PropTypes.func.isRequired,
};

export default compose(
  withSnackbar,
)(SquareButton);
